.hm1_bd1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
}

.hm1_bd1_aba {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.hm1_bd1_abaa {
  padding: 10px 20px;
  margin: 3px 25px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  border-radius: 3px;
  cursor: pointer;
}

.hm1_bd1_abaaa {
  height: 100px;
  display: flex;
  align-items: center;
}
.hm1_bd1_abaaaa {
  width: 100px;
  max-height: 100px;
}

.hm1_bd1_abaab {
  text-align: center;
  font-size: 15px;
  color: rgb(138, 138, 138);
}

/* //////////////////////////////////////////////////////////////////////////////////////// */
.hm1_bd2_aa {
  width: 100%;
  height: 40vw;
}

.hm1_bd2_ab {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.hm1_bd2_aba {
  width: calc(100% / 3);
  max-height: 500px;
  min-height: 500px;
  overflow: hidden;
  margin: 1.5px;
  cursor: pointer;
  position: relative;
}

.hm1_bd2_abaa {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hm1_bd2_abab {
  position: relative;
  top: 500px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hm1_bd2_aba:hover .hm1_bd2_abab {
  transition: 0.9s ease-in-out;
  top: 0px;
}

.hm1_bd2_aba:hover {
  background-size: cover;
  transition: 0.5s ease-in-out;
  width: 50%;
}

.hm1_bd2_aba:not(:hover) .hm1_bd2_abab {
  transition: 0.3s ease-in-out;
  top: 500px;
}

.hm1_bd2_aba:not(:hover) {
  background-size: cover;
  transition: 0.4s ease-in-out;
  width: calc(100% / 3);
}

.hm1_bd2_abaab {
  color: aliceblue;
  font-size: 20px;
  font-weight: bold;
}

.hm1_bd2_abaaa {
  display: flex;
  height: 120px;
  align-items: center;
}

.hm1_bd2_abaaaa {
  max-width: 200px;
  max-height: 100px;
}

/* ////////////////////////////////////////////////////////////////*/
.hm1_bt1_a {
  background-color: #333;
  padding: 20px 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.hm1_bt1_b {
  background-color: #252525;
  padding: 20px 5%;
  display: flex;
  justify-content: center;
  font-size: 10.5px;
  color: aliceblue;
}

.hm1_bt1_c {
  color: white;
  font-size: 12px;
  max-width: 200px;
  padding: 20px;
}

.hm1_bt1_d {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
}

.hm1_bt1_da {
  cursor: pointer;
  padding: 1px;
}

.hm1_bt1_da:hover {
  color: #1b65b1;
}

.hm1_bt1_c1 {
  display: none;
}

.hm1_bt1_c1a {
  width: 100%;
  border-right: 1px solid rgba(184, 184, 184, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11.5px;
  padding: 6px;
}

.hm1_bt1_c1aa {
  display: flex;
  align-items: center;
}

/* /////////////////////////////faq/////////////////////////// */

.hm1_qn1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 20px 1%;
  justify-self: center;
}

.hm1_qn1_aba {
  background-color: #f0f0f0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%-30px);
  margin-top: 2px;
}
.hm1_qn1_abaa { 
  font-size: 17px;
  font-weight: 600;
  color: #282828;
}
.hm1_qn1_abab {
  background-color: #f6f6f6;
  font-size: 15px;
  letter-spacing: 0.7px;
  color: #4a4a4a;
  padding: 15px;
  width: calc(100% - 30px);
}
