/* //////////////////////////////Headder///////////////////////////////// */

.cm1_bk_subtitle {
  font-size: 20px;
  padding: 10px 20px;
} 

.cm1_hd1_a {
  display: flex;
  width: 96%;
  justify-content: space-between;
  padding: 0px 2%;
  background-color: rgba(255, 255, 255, 0.568);
  position: static;
  top: 0px;
  border-bottom: 0.5px solid rgb(216, 216, 216);
}

.cm1_hd1_aa {
  height: 50px;
}

.cm1_hd1_ab {
  display: flex;
}

.cm1_hd1_aba {
  color: #0bb9c5;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin: 0px 20px;
  font-weight: 500;
}

.cm1_hd1_abaa {
  width: 16px;
  padding-right: 5px;
}

.cm1_hd1_abb {
  height: 50px;
  background: black;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin: 0px 20px;
}

.cm1_hd1_abba {
  height: 10px;
}

/* //////////////////titile///////////////////// */
.cm1_hm_title {
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  max-width: 1000px;
  justify-content: center;
}

.cm1_btn_a {
  display: flex;
  justify-content: end;
  padding: 20px;
  color: rgb(71, 173, 207);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.cm1_btn_a:hover {
  color: rgb(52, 184, 228);
}
