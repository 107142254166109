.bk1_dt1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 10px 0px;
  margin: 1% 10px;
}

.bk1_bh1_z {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  background-color: white;
  position: -webkit-sticky;
  width: 100%;
  overflow: auto;
}

.bk1_bh1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding-top: 10px;
  margin: 0% 0px;
}
.bk1_bh1_b {
  display: flex;
  box-shadow: 0px 2px 2px rgb(201, 201, 201);
}

.bk1_bh1_ba {
  font-size: 14px;
  color: #82888b;
  padding: 10px 15px;
  cursor: pointer;
}

.bk1_bh1_ba:hover {
  font-weight: bold;
}

.bk1_bh1_bb {
  background-color: #1e5d8f;
  height: 3px;
  width: 100%;
}

.bk1_dt1_aa {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgb(224, 224, 224);
  padding: 8px;
}

.bk1_dt1_aaa {
  font-size: 20px;
  padding: 5px 10px;
  font-weight: 600;
}

.bk1_dt1_aab {
  font-size: 15px;
  padding: 5px 10px;
  font-weight: 600;
  color: green;
}

.bk1_dt1_aac {
  font-size: 14px;
  padding: 5px 10px;
  color: rgb(87, 124, 204);
}

.bk1_dt1_c {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
}

.bk1_dt1_ca {
  width: 40%;
  padding: 15px;
}

.bk1_dt1_cb {
  width: 60%;
  padding: 10px;
}

.bk1_dt1_cb {
  padding: 20px;
}

.bk1_dt1_cba {
  display: flex;
  padding: 0px 10px;
}

.bk1_dt1_cbaa {
  font-size: 12px;
  color: #9b9b9b;
  padding: 0px 10px;
}

.bk1_dt1_cbc {
  font-size: 13px;
  color: #9b9b9b;
  padding: 5px;
}

.bk1_dt1_cbd {
  font-size: 20px;
  padding: 5px;
  color: #505050;
  font-weight: bold;
}

.bk1_dt1_cbe {
  font-size: 16px;
  margin: 5px;
  padding: 8px 10%;
  background-color: #1e5d8f;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.bk1_dt1_cbf {
  font-size: 12px;
  color: #9b9b9b;
  padding: 5px;
}

.bk1_dt1_cbg {
  font-size: 13px;
  color: #888888;
  padding: 0px 5px;
}

.bk1_dt1_cbh {
  height: 1px;
  background-color: rgb(211, 211, 211);
  margin: 15px 5px;
}

.bk1_dt1_cbi {
  display: flex;
  padding: 5px;
  align-items: center;
}

.bk1_dt1_cbiaa {
  color: rgb(168, 168, 168);
  font-size: 13px;
  font-weight: bold;
}
.bk1_dt1_cbiab {
  color: rgb(77, 125, 165);
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
}

.bk1_dt1_cbj {
  font-weight: 500;
  padding: 10px;
  color: #a0a0a0;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid rgb(207, 207, 207);
  white-space: nowrap;
}

.bk1_dt1_cbja {
  border-color: #e0e0e0;
  border-radius: 3px;
  margin: 0px 10px;
  width: 50px;
  color: #b8b8b8;
  width: 100%;
}

.bk1_dt1_cbib {
  border: 0.5px solid rgb(77, 125, 165);
  color: rgb(77, 125, 165);
  border-radius: 3px;
  padding: 6px 10px;
  margin: 0px 10px;
  font-size: 13px;
  cursor: pointer;
}

.bk1_dt1_cbb {
  display: flex;
}

.bk1_dt1_cbba {
  padding: 5px;
  /* width: 100px; */
}

.bk1_dt1_cbbaa {
  font-size: 12px;
  color: rgb(168, 168, 168);
}

.bk1_dt1_cbbab {
  font-size: 14px;
  color: rgb(71, 71, 71);
  padding: 3px;
  border-width: 0px;
}

.bk1_dt1_cbbab :focus {
  background-color: aqua;
}

.bk1_dt1_cacab {
  padding-top: 20px;
}

.bk1_dt1_cacaba {
  width: 100%;
  cursor: pointer;
}

.bk1_dt1_cacabb {
  display: flex;
  overflow: auto;
  padding: 10px;
  width: 100%;
}

.bk1_dt1_cacabba {
  width: 39px;
  height: 26px;
  margin: 3px;
  border: 2px solid gray;
  cursor: pointer;
}

/* /////////////////////////////bike spec //////////////////////////////// */

.bk1_sp1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 0px;
  margin: 0% 1%;
}

.bk1_sp1_aa {
  font-size: 18px;
  color: rgb(48, 48, 48);
  font-weight: bold;
  padding: 25px;
  box-shadow: 0px 2px 2px rgb(224, 224, 224);
}

.bk1_sp1_ab {
  padding: 22px;
  padding-bottom: 5px;
}

.bk1_sp1_aba {
  font-weight: bold;
  font-size: 15px;
  color: #3b3b3b;
  padding: 8px 12px;
}

.bk1_sp1_abb {
  display: flex;
  flex-wrap: wrap;
}

.bk1_sp1_abba {
  display: flex;
  max-width: 450px;
  width: 100%;
  padding: 12px;
}

.bk1_sp1_abbaa {
  min-width: 40%;
  font-size: 14px;
  color: #82888b;
}
.bk1_sp1_abbab {
  font-size: 14px;
  color: #777777;
  font-weight: bold;
}

.bk1_sp1_abbb {
  height: 1px;
  background-color: #dadada;
  width: 100%;
}

/* ////////////////////////////////Bike Summery /////////////////////////////////////// */

.bk1_sm_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 10px 0px;
  margin: 0% 0px;
}

.bk1_sm_ac {
  padding: 7px 20px;
  font-size: 15px;
  color: #5c5c5c;
}

.bk1_sm_ad {
  padding: 10px 15px;
}

.bk1_sm_ada {
  display: flex;
  padding: 15px;
}
.bk1_sm_adaa {
  width: 30%;
  font-size: 15px;
  color: #afafaf;
}
.bk1_sm_adab {
  font-size: 15px;
  color: #3b3b3b;
}
.bk1_sm_adb {
  height: 1px;
  width: 100%;
  background-color: #f1f1f1;
}

.bk1_sm_ae {
  height: 1px;
  width: calc(100% - 30px);
  background-color: #d8d8d8;
  margin: 0px 15px;
}
.bk1_sm_af {
  padding: 0px 30px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #8f8f8f;
  line-height: 24px;
}
